<template>
  <div class="page">
    <!--<div class="top">-->
    <!--<div class="back" v-on:click="back"><img src="../../assets/icon_3.png"/></div>-->
    <!--购买记录-->
    <!--</div>-->
    <div
      v-show="!isShow"
      style="width: 100%;text-align: center;margin: 20px 0;"
    >
      您还没有购买记录
    </div>
    <div
      v-show="isShow"
      class="orderList"
      v-for="(item, index) in orderList"
      v-bind:key="index"
    >
      <div class="info">
        <img src="../../assets/icon_8.png" />
        <div class="APPName">{{ item.appName }}</div>
        <div class="APPLenght" v-if="item.goodsSpecificationName">
          {{ item.goodsSpecificationName }}
        </div>
        <div class="APPAmount">￥{{ item.amount / 100 }}</div>
      </div>
      <!-- <div class="goodsName">{{item.goodsName}} </div> -->

      <div class="payOrderId">下单号：{{ item.id }}</div>
      <div class="orderTime" v-if="item.status === 0 || item.status === 2">
        下单时间：{{ item.orderTime }}
      </div>
      <div class="orderTime" v-else-if="item.status === 1 && item.type === 3">
        激活时间：{{ item.buyTime }}
      </div>
      <div class="orderTime" v-else>交易时间：{{ item.buyTime }}</div>
      <div
        v-if="item.status === 1 && item.type === 3"
        style="width: 100%;height: 21px;margin:15px 15px 0 0;"
      >
        <div class="status">已激活</div>
      </div>
      <div v-else style="width: 100%;height: 21px;margin: 15px 15px 0 0;">
        <div
          class="invoiceFlag"
          v-if="item.status === 1 && item.type === 1 && item.invoiceFlag === 0"
        >
          开票中
        </div>
        <div
          class="invoiceFlag"
          v-else-if="
            item.status === 1 && item.type === 1 && item.invoiceFlag === 1
          "
        >
          发票已开
        </div>
        <div
          class="invoiceFlag"
          v-else-if="
            item.status === 1 && item.type === 1 && item.invoiceFlag === 2
          "
        >
          开票失败
        </div>
        <div
          class="invoiceFlag"
          v-else-if="
            item.status === 1 && item.type === 1 && item.invoiceFlag === null
          "
          style="background-color: #fb5a10;"
          @click="toFP(item.id)"
        >
          申请发票
        </div>
        <div
          class="invoiceFlag"
          v-else-if="
            item.status === 0 && item.type === 1 && item.invoiceFlag === null
          "
          style="background-color: #288aff;"
          @click="toPay(item.id)"
        >
          继续支付
        </div>
        <div class="status" v-if="item.status === 0">待支付</div>
        <div class="status" v-else-if="item.status === 1">已支付</div>
        <div class="status" v-else-if="item.status === 2">支付失败</div>
      </div>
      <p v-if="appCode == 'sbw'" class="shan" @click="shanchu(item)">
        删除记录
      </p>
    </div>
    <div class="xz">
      <div class="xzTitle">
        <img
          src="../../assets/icon_9.png"
          style="float: left;width: 14px;margin-top: 1px;"
        />
        <div style="float: left;margin-left: 5px;font-size:14px;color:#333;">
          开票须知
        </div>
      </div>
      <div class="xzContent" v-if="appCode == 'taxlaw'">
        开票时间：无特殊或不可抗力情况下，我们将在7个工作日内为您开票，发票开出后可在您填写的接收邮箱中查看、下载。开票失败的情况请您识别下方二维码添加客服询问。
      </div>
      <div class="xzContent" v-else>
        开票时间：无特殊或不可抗力情况下，我们将在7个工作日内为您开票，发票开出后可在您填写的接收邮箱中查看、下载。开票失败的情况请您拨打客服电话询问。
      </div>
      <img
        v-if="appCode == 'taxlaw'"
        src="../../assets/wechatQrcode.jpg"
        style="width:150px"
      />
    </div>

    <van-overlay :show="show" @click="show = false">
      <div class="sscimg" v-if="this.appCode == 'Ssc'">
        <img src="../../assets/sscimg.png" alt="" />
      </div>
      <div class="wrapper" v-else @click.stop>
        <div class="block">
          <div class="dialogTop">填写开票信息</div>
          <van-tabs class="tab" v-model="tab" type="card">
            <!-- <van-tab title="专用发票" style="margin-right: 30px;">
              <div style="height: 33px;margin: 40px 10px 0 10px;">
                <div
                  style="width: 30%;text-align: right;float: left;line-height: 33px;font-size: 11px;color: #666666;margin-right: 10px;"
                >发票抬头</div>
                <van-field v-model="name" placeholder="请输入发票抬头"></van-field>
              </div>
              <div style="height: 33px;margin: 15px 10px 0 10px;">
                <div
                  style="width: 30%;text-align: right;float: left;line-height: 33px;font-size: 11px;color: #666666;margin-right: 10px;"
                >纳税人识别号</div>
                <van-field v-model="taxpayerNumber" placeholder="请输入纳税人识别号"></van-field>
              </div>
              <div style="height: 33px;margin: 15px 10px 0 10px;">
                <div
                  style="width: 30%;text-align: right;float: left;line-height: 33px;font-size: 11px;color: #666666;margin-right: 10px;"
                >地址</div>
                <van-field v-model="address" placeholder="请输入地址"></van-field>
              </div>
              <div style="height: 33px;margin: 15px 10px 0 10px;">
                <div
                  style="width: 30%;text-align: right;float: left;line-height: 33px;font-size: 11px;color: #666666;margin-right: 10px;"
                >联系电话</div>
                <van-field v-model="phone" placeholder="请输入联系电话"></van-field>
              </div>
              <div style="height: 33px;margin: 15px 10px 0 10px;">
                <div
                  style="width: 30%;text-align: right;float: left;line-height: 33px;font-size: 11px;color: #666666;margin-right: 10px;"
                >开户银行</div>
                <van-field v-model="bank" placeholder="请输入开户银行"></van-field>
              </div>
              <div style="height: 33px;margin: 15px 10px 0 10px;">
                <div
                  style="width: 30%;text-align: right;float: left;line-height: 33px;font-size: 11px;color: #666666;margin-right: 10px;"
                >银行帐号</div>
                <van-field v-model="bankCard" placeholder="请输入银行帐号"></van-field>
              </div>
              <div style="height: 33px;margin: 15px 10px 0 10px;">
                <div
                  style="width: 30%;text-align: right;float: left;line-height: 33px;font-size: 11px;color: #666666;margin-right: 10px;"
                >发票接收邮箱</div>
                <van-field v-model="email" placeholder="请输入邮箱"></van-field>
              </div>
              <div
                style="color: crimson;font-size: 12px;text-align: center;width: 100%;margin-top: 20px"
              >请确保抬头和纳税人识别号正常，开具后不可修改</div>
              <div style="margin: 20px 30px 0 30px">
                <div
                  style="color: #ffffff;font-size: 12px;line-height: 30px;border-radius: 15px;width: 100px;background-color: #c8c8c8;float: left;text-align: center;"
                  @click="off"
                >取消</div>
                <div
                  style="color: #ffffff;font-size: 12px;line-height: 30px;border-radius: 15px;width: 100px;background-color: #378aff;float: right;text-align: center;"
                  @click="sumbit(1)"
                >确定</div>
              </div>
            </van-tab> -->
            <van-tab title="普通发票">
              <div style="height: 33px;margin: 40px 10px 0 10px;">
                <div
                  style="width: 30%;text-align: right;float: left;line-height: 33px;font-size: 11px;color: #666666;margin-right: 10px;"
                >
                  发票抬头
                </div>
                <van-field
                  v-model="name2"
                  placeholder="请输入发票抬头"
                ></van-field>
              </div>
              <div style="height: 33px;margin: 15px 10px 0 10px;">
                <div
                  style="width: 30%;text-align: right;float: left;line-height: 33px;font-size: 11px;color: #666666;margin-right: 10px;"
                >
                  纳税人识别号
                </div>
                <van-field
                  v-model="taxpayerNumber2"
                  placeholder="请输入纳税人识别号"
                ></van-field>
              </div>
              <div style="height: 33px;margin: 15px 10px 0 10px;">
                <div
                  style="width: 30%;text-align: right;float: left;line-height: 33px;font-size: 11px;color: #666666;margin-right: 10px;"
                >
                  发票接收邮箱
                </div>
                <van-field
                  v-model="email2"
                  placeholder="请输入邮箱"
                ></van-field>
              </div>
              <div
                style="color: crimson;font-size: 12px;text-align: center;width: 100%;margin-top: 170px"
              >
                请确保抬头和纳税人识别号正常，开具后不可修改
              </div>
              <div style="margin: 20px 30px 0 30px">
                <div
                  style="color: #ffffff;font-size: 12px;line-height: 30px;border-radius: 15px;width: 100px;background-color: #c8c8c8;float: left;text-align: center;"
                  @click="off"
                >
                  取消
                </div>
                <div
                  style="color: #ffffff;font-size: 12px;line-height: 30px;border-radius: 15px;width: 100px;background-color: #378aff;float: right;text-align: center;"
                  @click="sumbit(2)"
                >
                  确定
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </div>
    </van-overlay>
    <!-- <div class="backButton"
        v-if="redirectUrl"
     :style="{top:y+'px',left:x+'px'}"
     @touchmove="moveing"
     @click.stop="backButton"
    >
      <img
        src="../../assets/returnlogo.png"
         @click.stop="openButton"
      />
    </div> -->
  </div>
</template>
<script>
import { orderList, invoices, getUserInfo, deleshan } from '@/api/member'
import { Toast } from 'vant'

export default {
  name: 'order',
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      {
        name: 'viewport',
        content:
          'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
      },
    ],
  },
  data() {
    return {
      appId: 0,
      show: false,
      tab: 1,
      name: '', //发票抬头
      name2: '', //普通-发票抬头
      taxpayerNumber: '', //纳税人识别号
      taxpayerNumber2: '', //普通-纳税人识别号
      email: '', //邮箱
      email2: '', //普通-邮箱
      address: '', //地址
      phone: '', //联系电话
      bank: '', //开户银行
      bankCard: '', //银行账号
      orderId: '',
      status: 0,
      orderList: [],
      isShow: true,
      userInfo: {}, //用户信息
      maxX: document.documentElement.clientWidth - 50,
      maxY: document.documentElement.clientHeight - 80,
      redirectUrl: '',
      minX: 0,
      minY: 0,
      x: 100,
      y: 100,
      isOpen: false,
      appCode: '',
    }
  },
  created: function() {
    let that = this
    this.x = this.maxX
    this.y = this.maxY - 50
    this.redirectUrl = this.$route.query.redirectUrl
    this.appCode = this.$route.query.appCode

    that.getIsLogin()
    //获取链接上携带的参数
    /*let newCookie = this.$route.query.token
    if (newCookie) {
      //存在cookie 就保存一下
      document.cookie = 'ZSDY_JSESSION_SID=' + newCookie
    }
    setTimeout(function() {
      that.getIsLogin()
    }, 100)*/
  },
  methods: {
    //获取信息
    getIsLogin() {
      getUserInfo().then((res) => {
        if (res.code === 401) {
          let url = '/api/snslogin/wxgzh?redirect_uri=' + location.href
          window.location.replace(url)
          // window.location.href = "/api/snslogin/wxgzh?redirect_uri=" + url;
        }
        this.userInfo = res.data
      })
      // this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 500,
      })
      this.getData()
    },
    //返回上一页
    back() {
      this.$router.go(-1)
    },
    //获取购买记录列表
    getData() {
      let that = this
      let data = {}
      if (this.$route.query.appCode !== undefined) {
        data = {
          page: 1,
          limit: 10000,
          appCode: this.$route.query.appCode,
          delFlag: 0,
        }
      } else {
        data = { page: 1, limit: 10000 }
      }
      orderList(data).then((res) => {
        that.orderList = res.rows
        if (res.rows.length === 0) {
          that.isShow = false
        }
      })
    },
    //申请发票
    toFP(id) {
      let that = this

      that.show = true
      that.orderId = id
    },
    //发票弹窗取消按钮
    off() {
      let that = this
      that.show = false
      that.orderId = ''
      that.status = 0
      that.name = ''
      that.name2 = ''
      that.taxpayerNumber = ''
      that.taxpayerNumber2 = ''
      that.email = ''
      that.email2 = ''
      that.address = ''
      that.phone = ''
      that.bank = ''
      that.bankCard = ''
    },
    //发票弹窗提交按钮
    sumbit(status) {
      let that = this
      let obj = {}
      if (status === 1) {
        if (
          that.orderId === '' ||
          that.name === '' ||
          that.taxpayerNumber === '' ||
          that.email === '' ||
          that.address === '' ||
          that.phone === '' ||
          that.bank === '' ||
          that.bankCard === ''
        ) {
          Toast('请完善所有信息！')
        } else {
          obj = {
            orderId: that.orderId,
            status: 1,
            name: that.name,
            taxpayerNumber: that.taxpayerNumber,
            email: that.email,
            address: that.address,
            phone: that.phone,
            bank: that.bank,
            bankCard: that.bankCard,
          }
          invoices(obj).then((res) => {
            window.console.log(res)
            that.orderId = ''
            that.status = 0
            that.name = ''
            that.name2 = ''
            that.taxpayerNumber = ''
            that.taxpayerNumber2 = ''
            that.email = ''
            that.email2 = ''
            that.address = ''
            that.phone = ''
            that.bank = ''
            that.bankCard = ''
            that.show = false
            Toast('提交成功')
            that.getData()
          })
        }
      } else if (status === 2) {
        if (
          that.orderId === '' ||
          that.name2 === '' ||
          that.taxpayerNumber2 === '' ||
          that.email2 === ''
        ) {
          Toast('请完善所有信息！')
        } else {
          obj = {
            orderId: that.orderId,
            status: 2,
            name: that.name2,
            taxpayerNumber: that.taxpayerNumber2,
            email: that.email2,
          }
        }
      }
      invoices(obj).then((res) => {
        window.console.log(res)
        that.orderId = ''
        that.status = 0
        that.name = ''
        that.name2 = ''
        that.taxpayerNumber = ''
        that.taxpayerNumber2 = ''
        that.email = ''
        that.email2 = ''
        that.address = ''
        that.phone = ''
        that.bank = ''
        that.bankCard = ''
        that.show = false

        if (res.msg === 'success') {
          Toast('申请成功')
        } else {
          Toast(res.msg)
        }
        that.getData()
      })
    },
    //待支付，继续支付
    toPay(orderId) {
      window.location.href = '/api/order/pay/' + orderId
    },
    //返回按钮开关
    openButton() {
      if (this.isOpen) {
        this.x = this.maxX
        this.isOpen = false
      } else {
        this.x = this.maxX - 90
        this.isOpen = true
      }
    },
    //点击返回相关app
    backButton() {
      window.console.log(this.redirectUrl)
      window.location.href = this.redirectUrl
    },
    //图标拖动事件
    moveing(e) {
      e.preventDefault()
      let y = e.changedTouches[0].clientY - 31 //减去高度的一半， 使手指与图标居中
      if (this.minY < y && y < this.maxY) {
        this.y = y
      } else if (y < this.minY) {
        this.y = 0
      } else if (y > this.maxY) {
        this.y = this.maxY
      }
    },
    shanchu(item) {
      let fd = new FormData()
      fd.append('orderId', item.id)
      deleshan(fd).then((res) => {
        window.console.log(res)
        if (res.msg === 'success') {
          Toast('删除成功')
        } else {
          Toast(res.msg)
        }
        this.getData()
      })
    },
  },
}
</script>

<style scoped>
.page {
  width: cacl(100% - 30px);
  background: #f6f7f9;
  padding: 10px 15px;
}
.orderList {
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  display: block;
  margin-bottom: 10px;
  padding-bottom: 15px;
  position: relative;
}
.shan {
  color: #0a7bf2;
  position: absolute;
  font-size: 14px;
  right: 10px;
  bottom: 60px;
}
.top {
  background-color: white;
  color: #222222;
  text-align: center;
  font-size: 24px;
  padding: 10px 20px 10px 20px;
}

.back {
  float: left;
}

.info {
  font-size: 16px;
  color: #333;
  padding: 25px 15px;
}
.info > img {
  width: 18px;
  height: 14.5px;
  display: inline-block;
  margin-right: 5px;
}
.info > .APPName {
  font-size: 16px;
  display: inline-block;
  margin-right: 10px;
}
.info > .APPLenght {
  font-size: 12px;
  display: inline-block;
  border: 1px solid #298afd;
  color: #298afd;
  border-radius: 5px;
  padding: 3px 6px;
}
.info > .APPAmount {
  font-size: 16px;
  color: #ec3232;
  font-weight: bold;
  display: inline-block;
  float: right;
}

.goodsName {
  font-size: 12px;
  color: #222222;
  width: 97%;
  margin: 0 10px 10px 10px;
}

.payOrderId {
  font-size: 13px;
  color: #777;
  margin: 0px 0px 15px 15px;
}

.orderTime {
  width: calc(100% - 20px);
  display: block;
  font-size: 13px;
  color: #777;
  padding-bottom: 15px;
  padding-left: 5px;
  margin: 0px 10px 0px 10px;
  border-bottom: 1px solid #ededed;
}
.status {
  float: right;
  font-size: 12px;
  color: #999999;
  margin-right: 12px;
  line-height: 25px;
}

.invoiceFlag {
  font-size: 13px;
  color: #ffffff;
  background-color: #288aff;
  width: 80px;
  line-height: 25px;
  height: 25px;
  float: right;
  text-align: center;
  border-radius: 20px;
  margin-right: 15px;
}

.xz {
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  display: block;
  padding-bottom: 15px;
}
.xz > img {
  display: block;
  margin: 10px auto;
}
.xzTitle {
  font-size: 13px;
  color: #333;
  padding: 15px;
}

.xzContent {
  margin: 15px 15px 0px 15px;
  font-size: 12px;
  color: #777;
  text-align: left;
  line-height: 24px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 93%;
  height: 560px;
  background-color: #fff;
  border-radius: 10px;
}

.dialogTop {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background-color: #378bf4;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.tab {
  width: 91%;
  height: 43px;
  margin: 20px 15px 0 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid #f0f5fa;
}

.tab >>> .van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: 0;
}

.tab >>> .van-tab {
  width: 40%;
  height: 40px;
  font-size: 12px;
  color: #666666;
  text-align: center;
  line-height: 40px;
  border: 1px solid #378bf4;
  border-radius: 10px;
  margin-right: 10px;
}

.tab >>> .van-tabs__line {
  display: none;
}

.tab >>> .van-tabs__wrap {
  height: 40px;
}

.tab >>> .van-tabs__nav--card {
  border: 0;
  margin: 0;
}

.tab >>> .van-tabs__nav--card {
  height: 40px;
}

.tab >>> .van-tabs__nav--card .van-tab.van-tab--active {
  background-color: #378bf4;
  color: white;
}

.tab >>> .van-cell {
  width: 66%;
  height: 33px;
  padding: 5px 10px 5px 10px;
  background-color: #f0f5fa;
  color: #888888;
  font-size: 10px;
}
.backButton {
  position: fixed;
  width: 150px;
  height: 62px;
  background: url('../../assets/return.png');
  background-size: contain;
  z-index: 999;
  transition: left 1.5s;
}
.backButton > img {
  position: relative;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
}
.sscimg >>> img {
  width: 70%;
  height: 70%;
  margin-left: 15%;
  margin-top: 15%;
}
</style>
